<script setup lang="ts">
withDefaults(
  defineProps<{
    rank?: 'essential' | 'tailor' | 'club'
    size?: number
  }>(),
  {
    rank: 'essential',
    size: 1.5,
  },
)

const starsCount = ref(3)
const starRanks = ref({
  essential: 1,
  tailor: 2,
  club: 3,
})
</script>

<template>
  <span class="flex">
    <span
      v-for="star in starsCount"
      :key="star"
      class="flex items-center justify-center rounded-full"
      :class="[
        {
          'mr-2': star < starsCount,
          'bg-primary-100 ': starRanks[rank] < star,
          'bg-primary-800': starRanks[rank] >= star,
          'size-[19px]': size === 0.7,
          'p-2': size === 1,
        },
      ]"
    >
      <BaseIcon
        :class="[
          'stroke-none',
          starRanks[rank] >= star ? 'fill-primary-100' : 'fill-primary-500',
        ]"
        :name="starRanks[rank] >= star ? 'cBeige' : 'cContour'"
        :size="size"
        filled
      />
    </span>
  </span>
</template>

<style>
.concierge-star-rank.icn {
  @apply flex box-border;
}
</style>
